<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Welcome to DetainEHR! 👋🏻
          </p>
          <p class="mb-2">
            Please sign-in to your account and begin charting.
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="loginForm"
          >
            <site-select
              :site="site"
              @site-select="updateSite"
            >
            </site-select>

            <v-text-field
              id="loginInputEmail"
              v-model="userName"
              class="mb-3"
              outlined
              label="User Name"
              placeholder="John.Doe"
              hide-details
            ></v-text-field>

            <v-text-field
              id="loginInputPassword"
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center  flex-wrap">
              <v-checkbox
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>
              <v-spacer>
              </v-spacer>
              <router-link
                :to="{name:'mfa-login'}"
                class="ms-3 mt-1"
              >
                <!--                Use Authenticator App?-->
              </router-link>
              <!-- forgot link -->
              <router-link
                :to="{name:'auth-forgot-password', params: { email: userName, token: ''}}"
                class="mt-1"
              >
                Register or Reset Password
              </router-link>
            </div>

            <v-dialog
              v-model="dialog"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  id="loginGetOTPBtn"
                  block
                  color="primary"
                  class="mt-6"
                  v-bind="attrs"
                  v-on="on"
                  @click="getOTP()"
                >
                  Login
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Privacy and HIPPA Policy
                </v-card-title>

                <v-card-text>
                  CONFIDENTIALITY ACKNOWLEDGMENT REGARDING ACCESS TO
                  ELECTRONIC MEDICAL RECORDS
                  Advanced Inmate Medical Management, LLC is hereinafter referred to as “AIMM.”
                  AIMM creates and maintains demographic and health information relating to patients in
                  correctional facilities (defined as “CONFIDENTIAL INFORMATION”). This CONFIDENTIAL
                  INFORMATION is located in computer information systems as well as paper charts and files. The
                  CONFIDENTIAL INFORMATION is protected from unauthorized or inappropriate access by
                  AIMM policy, as well as state and federal law.
                  AIMM wishes to grant USER appropriate access to CONFIDENTIAL INFORMATION contained
                  in AIMM INFORMATION SYSTEMS as needed to provide health care for those patients.
                  “AIMM INFORMATION SYSTEMS” is defined to include all AIMM computer hardware,
                  software, data or voice communication facilities, excluding AIMM web pages devoted to
                  employment job resources, and general public information.
                  1. Access to CONFIDENTIAL INFORMATION through AIMM INFORMATION
                  SYSTEMS. This access is provided to allow USER to obtain CONFIDENTIAL
                  INFORMATION to the extent necessary to provide health care to patients under the care
                  of USER and to permit effective and timely completion of medical records and orders.
                  2. Scope of Use. USER agrees not to gain access to, use, copy, makes notes of, remove,
                  divulge or disclose CONFIDENTIAL INFORMATION, except as necessary to provide
                  health care to patients under the care of USER and to permit effective and timely
                  completion of medical records and orders. USER agrees to control the access and use of
                  CONFIDENTIAL INFORMATION or AIMM INFORMATION SYSTEMS in a manner
                  to comply with this ACKNOWLEDGMENT.
                  3. Protection of Confidentiality and Security of CONFIDENTIAL INFORMATION.
                  USER agrees to protect the confidentiality and security of the CONFIDENTIAL
                  INFORMATION obtained from AIMM. USER agrees to comply with applicable federal
                  and state laws and with all existing and future AIMM policies and procedures concerning
                  the confidentiality, privacy, security, use and disclosure of CONFIDENTIAL
                  INFORMATION, which are available upon request.
                  4. Codes and Passwords. USER agrees not to release USER’s authentication code or device
                  or password to any other person or allow anyone else to access AIMM INFORMATION
                  SYSTEMS under USER’s authentication code or password. USER agrees to notify AIMM
                  immediately if USER becomes aware or suspects that another person has access to USER’s
                  authentication code or device or password.
                  5. Computer Security. USER agrees to maintain adequate security procedures for the
                  computers on which USER accesses the AIMM INFORMATION SYSTEMS. USER will
                  abide by the minimum security and AIMM hardware and software desktop standards as set
                  forth by AIMM. USER understands that USER is prohibited from accessing and using
                  AIMM INFORMATION SYSTEMS anywhere other than the correctional facility, unless
                  specific permission is given to USER from AIMM for use at USER’s home or other remote
                  CONFIDENTIALITY ACKNOWLEDGMENT REGARDING ACCESS TO
                  ELECTRONIC MEDICAL RECORDS
                  location. USER will not use or attempt to access AIMM INFORMATION SYSTEMS by
                  any means not specifically authorized by AIMM, including but not limited to the use of
                  any Internet or non-secure means of connection. USER will take no action to avoid or
                  disable any protection or security means implemented in the AIMM INFORMATION
                  SYSTEMS or otherwise use any means to access AIMM INFORMATION SYSTEMS
                  without following log-in procedures specified by AIMM.
                  6. Portable Media Devices. USER agrees that if USER saves CONFIDENTIAL
                  INFORMATION to portable media devices (Floppies, ZIP disks, CDs, PDAs, and other
                  devices), USER will take reasonable safeguards to protect the devices and
                  CONFIDENTIAL INFORMATION from any access or use not authorized by this
                  ACKNOWLEDGMENT. If USER is uncertain on how to protect CONFIDENTIAL
                  INFORMATION, USER will contact AIMM on how to protect CONFIDENTIAL
                  INFORMATION on the device while it is being serviced or repaired. USER agrees that if
                  any portable media device needs to be reformatted or destroyed, USER will follow
                  guidelines of AIMM for proper data cleansing or follow any policies or guidelines provided
                  by AIMM for reformatting or destruction of electronic media.
                  7. Printing CONFIDENTIAL INFORMATION. If USER prints CONFIDENTIAL
                  INFORMATION, USER will take reasonable safeguards to protect the printed
                  CONFIDENTIAL INFORMATION from access or use not authorized by this
                  ACKNOWLEDGMENT, and thereafter destroy such copies when they are no longer
                  required for the purposes authorized herein.
                  8. Return of Software or Equipment. Upon request by AIMM, USER agrees immediately
                  to return any software or equipment provided to USER by AIMM.
                  9. Auditing Compliance. USER agrees that USER’s compliance with this
                  ACKNOWLEDGMENT may be subject to review and/or audit by AIMM.
                  10. Limitation of Liability of AIMM/Exclusions of Warranties. The parties agree that
                  USER is responsible for the ultimate decisions and medical judgment related to the
                  diagnosis and treatment of his/her patients based on CONFIDENTIAL INFORMATION
                  accessed on AIMM INFORMATION SYSTEMS. USER understands and agrees that
                  remote access to electronic records involves technological risks, including possible
                  introduction of errors, data corruption, and artifacts that may not be present on original
                  versions of radiological results. USER understands that images accessed remotely may not
                  have the same degree of clarity as images viewed on-site.
                  USER agrees that AIMM will not be liable for any direct, indirect, incidental, special or
                  other damages incurred by USER arising out of the remote use of or inability to use the
                  AIMM INFORMATION SYSTEMS. AIMM does not guarantee or warrant the availability
                  of remote access of AIMM INFORMATION SYSTEMS.
                  CONFIDENTIALITY ACKNOWLEDGMENT REGARDING ACCESS TO
                  ELECTRONIC MEDICAL RECORDS
                  The parties recognize that remote access introduces unique risks associated with unrelated
                  software that may exist on the remote access device that compromises the integrity and
                  security of data and remote access, including but not limited to spyware, hacker access,
                  viruses, worms, and other harmful software (collectively referred to as “REMOTE
                  ACCESS RISKS”). Accordingly, AIMM will not be responsible for any losses or damages
                  related to REMOTE ACCESS RISKS.
                  11. Response to Confidentiality Concerns. Whenever AIMM in its sole judgment and
                  discretion believes that USER has obtained unauthorized access to CONFIDENTIAL
                  INFORMATION, has disclosed CONFIDENTIAL INFORMATION inappropriately or in
                  violation of federal or state laws or regulations, has violated any AIMM policies or
                  procedures regarding confidentiality or the use of CONFIDENTIAL INFORMATION, or
                  has violated any provisions of this ACKNOWLEDGMENT, AIMM is also entitled to take
                  any or all of the following actions immediately, as it determines to be appropriate:
                  a. Suspend or terminate USER’s remote access to AIMM INFORMATION
                  SYSTEMS until AIMM concerns are addressed.
                  b. Refer USER to AIMM per review process and report USER to a professional board,
                  as appropriate.
                  12. Continuing Obligations. USER agrees that the obligations under this
                  ACKNOWLEDGMENT continue in the event his or her staff privileges with the
                  correctional facility are terminated or expire, or in the event AIMM terminates this
                  ACKNOWLEDGMENT.
                  13. Termination. This ACKNOWLEDGMENT will be effective as of the date signed.
                  However, AIMM reserves the right to terminate USER’s access to AIMM
                  INFORMATION SYSTEMS at any time at their discretion for reasons including, but not
                  limited to, misuse of USER’s access, failure to adhere to the conditions of this
                  ACKNOWLEDGMENT or AIMM policies, or a breach of confidentiality. Furthermore,
                  as an additional security measure, if USER has not logged in for a period of 60 days,
                  USER’s account may automatically be disabled.
                  a. If AIMM’s services are terminated with the correctional facility, the USER should
                  expect their account to be disabled and no longer valid and USER should not expect
                  to have access to the electronic records.
                  b. A USER who is no longer employed by the correctional facility should expect their
                  access to be terminated.
                  14. Waiver. Any waiver of any of the terms or conditions of this ACKNOWLEDGMENT by
                  any party will not be construed as a general waiver of the same condition for any
                  subsequent breach.
                  CONFIDENTIALITY ACKNOWLEDGMENT REGARDING ACCESS TO
                  ELECTRONIC MEDICAL RECORDS
                  15. Additional Safeguards. USER understands and agrees that the HIPAA Security
                  Regulations require AIMM and USER to implement security and privacy processes,
                  practices and technical requirements in connection with the access and use of electronic
                  CONFIDENTIAL INFORMATION. AIMM reserves the right to impose additional
                  security safeguards, including without limitation software and hardware requirements, to
                  comply with the Security Standards.
                </v-card-text>

                <v-divider></v-divider>
                <v-card-text>
                  BY ENTERING YOUR PASSCODE AND CLICKING I ACCEPT YOU ARE AGREEING TO THE ABOVE TERMS OF USE.
                </v-card-text>
                <v-card-actions>
                  <v-text-field
                    v-model="otp"
                    outlined
                    label="Pass Code"
                    placeholder="Enter OTP from your email"
                  >
                  </v-text-field>

                  <v-spacer></v-spacer>
                  <v-btn
                    color="secondary"
                    text
                    @click="dialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    id="loginFinalBtn"
                    color="primary"
                    text
                    :disabled="buttonIsDisabled"
                    @click.once="agree = true"
                    @click="submitForm()"
                  >
                    I accept
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-form>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { useRouter } from '@core/utils'
import { emailValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { getCurrentInstance, ref, onUnmounted } from '@vue/composition-api'
import SiteSelect from '@/components/site/SiteSelect.vue'
import authenticationService from '@/services/AuthenticationService'
import useAuthenticationService from '@/store/useAuthenticationService'
import variables from '@/variables'

export default {
  components: {
    SiteSelect,
  },
  data() {
    return {
      buttonIsDisabled: true,
      otp: '',
      site: null,
      siteId: '',
      userName: '',
      password: '',
      isPasswordVisible: false,
      errorMessages: {
        userName: [],
        password: [],
        validation: [],
      },
      dialog: false,
      agree: false,
    }
  },
  setup() {
    const AUTH_APP = 'app-auth'

    // Register module
    if (!store.hasModule(AUTH_APP)) {
      store.registerModule(AUTH_APP, authenticationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUTH_APP)) store.unregisterModule(AUTH_APP)
    })

    const {
      tokens, EntryDTO, postAuthAttempt, errorMessages, rolePackage, userData, oTPLogin,
    } = useAuthenticationService()

    // Template Ref
    const loginForm = ref(null)

    const isPasswordVisible = ref(false)

    const vm = getCurrentInstance().proxy

    const { router } = useRouter()

    const RedirectToDashboard = () => {
      try {
        const userAbility = JSON.parse(localStorage.getItem('userAbility'))
        vm.$ability.update(userAbility)

        router.push('/dashboards/home')
      } catch (exception) {
        alert(`Redirection Failed due to ${exception}`)
      }
    }

    const validate = () => {
      if (loginForm.value.validate(EntryDTO)) {
        return true
      }

      return false
    }

    return {
      isPasswordVisible,
      errorMessages,

      oTPLogin,
      postAuthAttempt,
      EntryDTO,
      tokens,
      rolePackage,

      userData,

      router,
      validate,

      RedirectToDashboard,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
  watch: {
    otp(newValue, oldValue) {
      this.buttonIsDisabled = false
    },
  },
  methods: {
    updateSite(siteDTO) {
      this.site = siteDTO.value
      this.siteId = siteDTO.value.id
    },
    updateUser(userName) {
      this.userName = userName
    },
    updatePassword(password) {
      this.password = password
    },
    async getOTP() {
      try {
        const EntryDTO = { Email: this.userName, Password: this.password, siteId: this.siteId }

        if (!this.validate(EntryDTO)) {
          return
        }

        await this.postAuthAttempt(EntryDTO)
      } catch (error) {
        alert(`login vue error: ${error}`)
      }
    },
    async verifyOtp() {
      const url = `${variables.INTERNAL_API}/api/v1/Auth/verifyOtp`
      try {
        const response = await this.$http.post(url, {
          email: this.userName,
          otp: this.otp,
        })
        if (response.status === 200) {
          alert('OTP verified successfully')
          this.RedirectToDashboard()
        } else {
          alert('Invalid or expired one-time passcode')
        }
      } catch (error) {
        console.log('Error verifying OTP: ', error)
        alert('Sorry, that was an invalid or expired one-time passcode.')
      }
    },
    async submitForm() {
      if (this.agree) {
        const SecondStep = {
          Email: this.userName,
          Password: this.otp,
          siteId: this.siteId,
        }

        await this.oTPLogin(SecondStep)

        const isOtpValid = await this.verifyOtp(this.email, this.otp)
        if (isOtpValid) {
          this.RedirectToDashboard()

        // } else {
        //   alert('Invalid or expired OTP')
        }

        // this.RedirectToDashboard()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
